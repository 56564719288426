<template>
  <div
    class="w-11/12 pl-16 border-l-2 border-primary flex items-start relative"
    :class="[!allChecked ? 'border-primary' : 'border-success']"
  >
    <div class="h-full flex flex-col">
      <div
        class="bg-whiteBlue rounded-full flex items-center justify-center absolute -left-8"
        :class="[
          allChecked ? '' : ['h-16', 'w-16', 'border-2', 'border-primary'],
        ]"
      >
        <PawsIcon v-if="!allChecked" width="36" height="36" />
        <CheckIcon v-else width="62" height="62" iconColor="#04BE01" />
      </div>
    </div>
    <div
      class="w-full p-5 bg-white rounded-md text-left font-proximaMedium relative"
    >
      <a @click="routeTo">
        <h5 class="text-subheaderText text-lg cursor-pointer">
          Create your first pet record!
        </h5>
      </a>

      <p class="mt-1 mb-4 font-proximaLight text-sm">
        Have your pet’s microchip number (and if applicable, pedigree
        registration) ready.
      </p>
      <!-- <ul v-if="expand">
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon width="20" height="20" />
            <img v-if="checklist" src="@/assets/images/WaitingCheckIcon.png" alt="WaitingCheckIcon" />
          </div>
          Create pet record
        </li>
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon width="20" height="20" />
            <img v-if="checklist" src="@/assets/images/WaitingCheckIcon.png" alt="WaitingCheckIcon" />
          </div>
          Create pet record
        </li>
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon width="20" height="20" />
            <img v-if="checklist" src="@/assets/images/WaitingCheckIcon.png" alt="WaitingCheckIcon" />
          </div>
          Create pet record
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import PawsIcon from "../../icons/PawsIcon.vue";
import CheckIcon from "../../icons/CheckIcon.vue";

export default {
  props: ["allChecked"],
  components: {
    PawsIcon,
    CheckIcon,
  },
  data() {
    return {
      checklist: false,
      expand: this.allChecked,
    };
  },
  methods: {
    routeTo() {
      if (!this.allChecked) this.$router.push({ path: "/petoverview/new" });
    },
  },
};
</script>

<style></style>