<template>
  <div
    class="w-11/12 pt-8 pl-16 border-l-2 border-primary flex items-start relative"
    :class="[
      !allChecked ? ['border-primary', 'pb-4'] : ['border-success', 'pb-14'],
    ]"
  >
    <div class="h-full flex flex-col">
      <div
        class="bg-whiteBlue rounded-full flex items-center justify-center absolute -left-8"
        :class="[
          !allChecked ? ['h-16', 'w-16', 'border-2', 'border-primary'] : '',
        ]"
      >
        <MedicalhistIcon v-if="!allChecked" width="36" height="36" />
        <CheckIcon v-else width="62" height="62" iconColor="#04BE01" />
      </div>
      <div v-if="expand" class="absolute top-24 -left-3">
        <div class="my-4 bg-whiteBlue">
          <CheckIcon
            v-if="checklist1"
            width="22"
            height="22"
            iconColor="#04BE01"
          />
          <img
            v-else
            src="@/assets/images/WaitingGreenIcon.png"
            alt="WaitingGreenIcon"
          />
        </div>
        <div class="my-4 bg-whiteBlue">
          <CheckIcon
            v-if="checklist2"
            width="22"
            height="22"
            iconColor="#04BE01"
          />
          <img
            v-else
            width="22"
            height="22"
            src="@/assets/images/WaitingGreenIcon.png"
            alt="WaitingGreenIcon"
          />
        </div>
        <div class="my-4 bg-whiteBlue">
          <CheckIcon
            v-if="checklist3"
            width="22"
            height="22"
            iconColor="#04BE01"
          />
          <img
            v-else
            width="22"
            height="22"
            src="@/assets/images/WaitingGreenIcon.png"
            alt="WaitingGreenIcon"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full p-5 bg-white rounded-md text-left font-proximaMedium relative"
    >
      <img
        v-if="!allChecked"
        @click="expand = !expand"
        :class="[
          expand ? ['rotate-90', 'transform'] : ['rotate-0', 'transform'],
        ]"
        class="absolute top-11 right-6 h-4"
        src="@/assets/images/arrow-icon.png"
        alt="arrow-icon"
      />
      <!-- /vaccination -->
      <a v-if="firstPetRecord" @click="routeTo2">
        <h5 class="text-subheaderText text-lg cursor-pointer">
          Activate the medical history!
        </h5>
      </a>
      <a v-else>
        <h5 class="text-subheaderText text-lg cursor-not-allowed">
          Activate the medical history!
        </h5>
      </a>
      <p
        v-if="!allChecked"
        @click="expand = !expand"
        class="mt-1 mb-4 font-proximaLight text-sm cursor-pointer"
      >
        Have your pet’s official documents (EU pet passport / veterinary bill /
        pedigree) ready.
      </p>
      <ul v-if="expand && !allChecked">
        <li class="mb-2 flex items-center cursor-pointer">
          <div class="mr-6">
            <CheckIcon
              v-if="checklist1"
              width="20"
              height="20"
            />
            <img
              v-else
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          <a v-if="propsProgress != 0" @click="routeTo">
            Verify your pet’s identity
          </a>
          <div v-else class="cursor-not-allowed">
            Verify your pet’s identity
          </div>
        </li>
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon v-if="checklist2" width="20" height="20" />
            <img
              v-else
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          <a
            class="cursor-pointer"
            v-if="propsProgress != 0"
            @click="$router.push({ path: '/vaccination' })"
          >
            Add your pet’s vaccination history
          </a>
          <div v-else class="cursor-not-allowed">
            Add your pet’s vaccination history
          </div>
        </li>
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon v-if="checklist3" width="20" height="20" />
            <img
              v-else
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          <a
            class="cursor-pointer"
            v-if="propsProgress != 0"
            @click="$router.push({ path: '/nutritionplan' })"
          >
            Add your pet’s nutritional plan
          </a>
          <div v-else class="cursor-not-allowed">
            Add your pet’s nutritional plan
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="allChecked"
      class="font-proximaMedium flex items-center absolute -bottom-0"
    >
      <PanicButtonIcon />
      <span class="ml-2 text-subheaderText">
        The <span class="text-primary">panic button</span> feature has been
        activated!
      </span>
    </div>
  </div>
</template>

<script>
import MedicalhistIcon from "../../icons/MedicalhistIcon.vue";
import CheckIcon from "../../icons/CheckIcon.vue";
import PanicButtonIcon from "../../icons/PanicButtonIcon.vue";

export default {
  props: [
    "allChecked",
    "checklist1",
    "checklist2",
    "checklist3",
    "propsProgress",
    "firstPetRecord",
  ],
  components: {
    MedicalhistIcon,
    CheckIcon,
    PanicButtonIcon,
  },
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    routeTo() {
      // Put the pet object into storage
      const firstpetObject = localStorage.getItem("firstpet");

      const pet = JSON.parse(firstpetObject);
      localStorage.setItem("pet", JSON.stringify(pet));
      console.log(pet);
      let v = "";
      const petSpices = pet.species;

      v = "PetRecord";

      this.$router.push({ name: v, params: { pet: pet } });
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight
      );
    },
    routeTo2() {
      const progress20 = this.propsProgress;

      if (!this.allChecked && progress20 != 0)
        this.$router.push({ path: "/intakequestionnaire" });
    },
  },
};
</script>

<style></style>