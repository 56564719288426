<template>
  <div
    class="w-11/12 py-4 pl-16 border-l-2 border-primary flex items-start relative"
    :class="[!allChecked ? 'border-primary' : 'border-success']"
  >
    <div class="h-full flex flex-col">
      <div
        class="bg-whiteBlue rounded-full flex items-center justify-center absolute -left-8"
        :class="[
          !allChecked ? ['h-16', 'w-16', 'border-2', 'border-primary'] : '',
        ]"
      >
        <HeartIcon
          v-if="!allChecked"
          width="28"
          height="28"
          iconColor="#0AC0CB"
        />
        <CheckIcon v-else width="62" height="62" iconColor="#04BE01" />
      </div>
      <div v-if="expand && !allChecked" class="absolute top-20 -left-3">
        <div class="my-4 bg-whiteBlue">
          <CheckIcon
            v-if="checklist1"
            width="22"
            height="22"
            iconColor="#04BE01"
          />
          <img
            v-else
            src="@/assets/images/WaitingGreenIcon.png"
            alt="WaitingGreenIcon"
          />
        </div>
        <div class="my-4 bg-whiteBlue">
          <CheckIcon
            width="22"
            height="22"
            iconColor="#04BE01"
            v-if="checklist2"
          />
          <img
            v-else
            width="22"
            height="22"
            src="@/assets/images/WaitingGreenIcon.png"
            alt="WaitingGreenIcon"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full p-5 bg-white rounded-md text-left font-proximaMedium relative"
    >
      <img
        v-if="!allChecked"
        :class="[
          expand ? ['rotate-90', 'transform'] : ['rotate-0', 'transform'],
        ]"
        class="absolute top-8 right-6 h-4"
        src="@/assets/images/arrow-icon.png"
        alt="arrow-icon"
      />
      <h5
        @click="expand = !expand"
        class="text-subheaderText text-lg cursor-pointer"
      >
        Add a favourite pet service!
      </h5>
      <p
        v-if="!allChecked"
        @click="expand = !expand"
        class="mt-1 mb-4 font-proximaLight text-sm cursor-pointer"
      >
        Connect to your favourite service here on the platform, or recommend one
        if they’re not listed!
      </p>
      <ul v-if="expand && !allChecked">
        <li class="mb-2 flex items-center cursor-pointer">
          <div class="mr-6">
            <CheckIcon
              v-if="checklist2 && !allChecked"
              width="20"
              height="20"
            />
            <img
              v-else
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          <a @click="routeTo"> Recommend a service for our directory </a>
        </li>
        <li class="mb-2 flex items-center cursor-pointer">
          <div class="mr-6">
            <CheckIcon v-if="checklist1" width="20" height="20" />
            <img
              v-else
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          Search for and connect to your favourite service
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HeartIcon from "../../icons/HeartIcon.vue";
import CheckIcon from "../../icons/CheckIcon.vue";

export default {
  props: ["allChecked", "checklist1", "checklist2"],
  components: {
    HeartIcon,
    CheckIcon,
  },
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    routeTo() {
      this.$router.push({ path: "/browseservicedirectory" });
      this.$store.commit("suggestModals", true);
    },
  },
};
</script>

<style></style>