<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.08 0H0.92C0.411125 0 0 0.411125 0 0.92V22.08C0 22.5889 0.411125 23 0.92 23H22.08C22.5889 23 23 22.5889 23 22.08V0.92C23 0.411125 22.5889 0 22.08 0ZM20.93 20.93H2.07V2.07H20.93V20.93ZM10.925 8.28H16.215C16.3415 8.28 16.445 8.1765 16.445 8.05V6.67C16.445 6.5435 16.3415 6.44 16.215 6.44H10.925C10.7985 6.44 10.695 6.5435 10.695 6.67V8.05C10.695 8.1765 10.7985 8.28 10.925 8.28ZM10.925 12.42H16.215C16.3415 12.42 16.445 12.3165 16.445 12.19V10.81C16.445 10.6835 16.3415 10.58 16.215 10.58H10.925C10.7985 10.58 10.695 10.6835 10.695 10.81V12.19C10.695 12.3165 10.7985 12.42 10.925 12.42ZM10.925 16.56H16.215C16.3415 16.56 16.445 16.4565 16.445 16.33V14.95C16.445 14.8235 16.3415 14.72 16.215 14.72H10.925C10.7985 14.72 10.695 14.8235 10.695 14.95V16.33C10.695 16.4565 10.7985 16.56 10.925 16.56ZM6.555 7.36C6.555 7.665 6.67616 7.95751 6.89183 8.17317C7.10749 8.38884 7.4 8.51 7.705 8.51C8.01 8.51 8.30251 8.38884 8.51817 8.17317C8.73384 7.95751 8.855 7.665 8.855 7.36C8.855 7.055 8.73384 6.76249 8.51817 6.54683C8.30251 6.33116 8.01 6.21 7.705 6.21C7.4 6.21 7.10749 6.33116 6.89183 6.54683C6.67616 6.76249 6.555 7.055 6.555 7.36V7.36ZM6.555 11.5C6.555 11.805 6.67616 12.0975 6.89183 12.3132C7.10749 12.5288 7.4 12.65 7.705 12.65C8.01 12.65 8.30251 12.5288 8.51817 12.3132C8.73384 12.0975 8.855 11.805 8.855 11.5C8.855 11.195 8.73384 10.9025 8.51817 10.6868C8.30251 10.4712 8.01 10.35 7.705 10.35C7.4 10.35 7.10749 10.4712 6.89183 10.6868C6.67616 10.9025 6.555 11.195 6.555 11.5ZM6.555 15.64C6.555 15.945 6.67616 16.2375 6.89183 16.4532C7.10749 16.6688 7.4 16.79 7.705 16.79C8.01 16.79 8.30251 16.6688 8.51817 16.4532C8.73384 16.2375 8.855 15.945 8.855 15.64C8.855 15.335 8.73384 15.0425 8.51817 14.8268C8.30251 14.6112 8.01 14.49 7.705 14.49C7.4 14.49 7.10749 14.6112 6.89183 14.8268C6.67616 15.0425 6.555 15.335 6.555 15.64V15.64Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>