<template>
  <div
    class="w-11/12 py-4 pl-16 border-l-2 border-primary flex items-start relative"
    :class="[!allChecked ? 'border-primary' : 'border-success']"
  >
    <div class="h-full flex flex-col">
      <div
        class="bg-whiteBlue rounded-full flex items-center justify-center absolute -left-8"
        :class="[
          !allChecked ? ['h-16', 'w-16', 'border-2', 'border-primary'] : '',
        ]"
      >
        <DiaryIcon
          v-if="!allChecked"
          width="32"
          height="32"
          iconColor="#0AC0CB"
        />
        <CheckIcon v-else width="62" height="62" iconColor="#04BE01" />
      </div>
    </div>
    <div
      class="w-full p-5 bg-white rounded-md text-left font-proximaMedium relative cursor-pointer"
      :class="[!sharePetRecordsChecked, 'cursor-not-allowed']"
    >
      <a v-if="sharePetRecordsChecked" @click="routeTo">
        <h5 class="text-subheaderText text-lg">
          Create your first diary entry!
        </h5>
      </a>
      <div v-else>
        <h5 class="text-subheaderText text-lg">
          Create your first diary entry!
        </h5>
      </div>
      <p class="mt-1 mb-4 font-proximaLight text-sm">
        Keep track of any quirks or just good memories.
      </p>
    </div>
  </div>
</template>

<script>
import DiaryIcon from "../../icons/DiaryIcon.vue";
import CheckIcon from "../../icons/CheckIcon.vue";

export default {
  props: ["allChecked", "propsProgress", "sharePetRecordsChecked"],
  components: {
    DiaryIcon,
    CheckIcon,
  },
  data() {
    return {
      checklist: false,
    };
  },
  methods: {
    routeTo() {
      this.$store.commit("bulkShareModals", true);
      this.$router.push({ path: "/petownerdiary/dashboard" });
    },
  },
};
</script>

<style>
</style>