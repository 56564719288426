<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 21.875C9.91032 21.875 11.0231 21.4141 11.8436 20.5936C12.6641 19.7731 13.125 18.6603 13.125 17.5C13.125 16.3397 12.6641 15.2269 11.8436 14.4064C11.0231 13.5859 9.91032 13.125 8.75 13.125C7.58968 13.125 6.47688 13.5859 5.65641 14.4064C4.83594 15.2269 4.375 16.3397 4.375 17.5C4.375 18.6603 4.83594 19.7731 5.65641 20.5936C6.47688 21.4141 7.58968 21.875 8.75 21.875V21.875Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.25 13.125C27.4103 13.125 28.5231 12.6641 29.3436 11.8436C30.1641 11.0231 30.625 9.91032 30.625 8.75C30.625 7.58968 30.1641 6.47688 29.3436 5.65641C28.5231 4.83594 27.4103 4.375 26.25 4.375C25.0897 4.375 23.9769 4.83594 23.1564 5.65641C22.3359 6.47688 21.875 7.58968 21.875 8.75C21.875 9.91032 22.3359 11.0231 23.1564 11.8436C23.9769 12.6641 25.0897 13.125 26.25 13.125Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.25 30.625C27.4103 30.625 28.5231 30.1641 29.3436 29.3436C30.1641 28.5231 30.625 27.4103 30.625 26.25C30.625 25.0897 30.1641 23.9769 29.3436 23.1564C28.5231 22.3359 27.4103 21.875 26.25 21.875C25.0897 21.875 23.9769 22.3359 23.1564 23.1564C22.3359 23.9769 21.875 25.0897 21.875 26.25C21.875 27.4103 22.3359 28.5231 23.1564 29.3436C23.9769 30.1641 25.0897 30.625 26.25 30.625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6875 15.599L22.3125 10.6406"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6875 19.3906L22.3125 24.349"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>