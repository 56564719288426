<template>
  <div
    class="w-11/12 py-4 pl-16 border-l-2 border-primary flex items-start relative"
    :class="[!allChecked ? 'border-primary' : 'border-success']"
  >
    <div class="h-full flex flex-col">
      <div
        class="bg-whiteBlue rounded-full flex items-center justify-center absolute -left-8"
        :class="[
          !allChecked ? ['h-16', 'w-16', 'border-2', 'border-primary'] : '',
        ]"
      >
        <CareroutineIcon v-if="!allChecked" width="28" height="28" />
        <CheckIcon v-else width="62" height="62" iconColor="#04BE01" />
      </div>
    </div>
    <div
      class="w-full p-5 bg-white rounded-md text-left font-proximaMedium relative"
      :class="[!medicalHistoryChecked, 'cursor-not-allowed']"
    >
      <a v-if="medicalHistoryChecked" @click="routeTo">
        <h5 class="text-subheaderText text-lg">Add your pet’s care routine!</h5>
      </a>
      <div v-else>
        <h5 class="text-subheaderText text-lg">Add your pet’s care routine!</h5>
      </div>
      <p class="mt-1 mb-4 font-proximaLight text-sm">
        Add your pet’s daily needs like meal times and walks.
      </p>
      <!--   <ul v-if="expand">
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon width="20" height="20" />
            <img
              v-if="checklist"
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          Create pet record
        </li>
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon width="20" height="20" v-if="checklist" />
            <img
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          Create pet record
        </li>
        <li class="mb-2 flex items-center">
          <div class="mr-6">
            <CheckIcon width="20" height="20" v-if="checklist" />
            <img
              src="@/assets/images/WaitingCheckIcon.png"
              alt="WaitingCheckIcon"
            />
          </div>
          Create pet record
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import CareroutineIcon from "../../icons/CareroutineIcon.vue";
import CheckIcon from "../../icons/CheckIcon.vue";

export default {
  props: ["allChecked", "propsProgress", "medicalHistoryChecked"],
  components: {
    CareroutineIcon,
    CheckIcon,
  },
  data() {
    return {
      checklist: false,
    };
  },
  methods: {
    routeTo() {
      this.$store.commit("bulkShareModals", true);
      this.$router.push({ path: "/routineoverview" });
    },
  },
};
</script>

<style>
</style>