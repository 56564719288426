<template>
  <div
    class="w-11/12 py-4 pl-16 border-l-2 border-primary flex items-start relative"
    :class="[!allChecked ? 'border-primary' : 'border-success']"
  >
    <div class="h-full flex flex-col">
      <div
        class="bg-whiteBlue rounded-full flex items-center justify-center absolute -left-8"
        :class="[
          !allChecked ? ['h-16', 'w-16', 'border-2', 'border-primary'] : '',
        ]"
      >
        <PhoneIcon
          v-if="!allChecked"
          width="26"
          height="26"
          iconColor="#0AC0CB"
        />
        <CheckIcon v-else width="62" height="62" iconColor="#04BE01" />
      </div>
    </div>
    <div
      class="w-full p-5 bg-white rounded-md text-left font-proximaMedium relative"
    >
      <a @click="routeTo">
        <h5 class="text-subheaderText text-lg cursor-pointer">
          Add an emergency contact!
        </h5>
      </a>
      <p class="mt-1 mb-4 font-proximaLight text-sm cursor-pointer">
        Add details of a trustworthy contact for friends, sitters and your own
        peace of mind.
      </p>
    </div>
  </div>
</template>

<script>
import PhoneIcon from "../../icons/PhoneIcon.vue";
import CheckIcon from "../../icons/CheckIcon.vue";

export default {
  props: ["allChecked"],
  components: {
    PhoneIcon,
    CheckIcon,
  },
  data() {
    return {
      checklist: this.allChecked,
    };
  },
  methods: {
    routeTo() {
      //open emergency contact modal
      this.$router.push({ path: "/contacts" });
      this.$store.commit("addEmergencyContactModals", true);
    },
  },
};
</script>

<style></style>