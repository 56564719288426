<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12">
        <img
          class="h-32 w-auto px-4 md:pl-16 lg:pl-32 pt-8 hidden md:flex"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <template>
        <div
          class="text-sm text-right font-proximaLight flex justify-center items-center"
        >
          <!-- to show navigation -->
          <NavMenu :user="user" :role="role" class="block" />
          <!-- to show navigation -->
        </div>
      </template>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="w-10/12 lg:w-7/12 flex flex-col justify-center items-center">
        <div
          class="w-full mb-14 text-left font-proximaMedium text-subheaderText"
        >
          <div class="w-full flex flex-col justify-start">
            <h3 v-if="progress == 0" class="text-left text-2xl">
              Get Started with PerPetual
            </h3>
            <h3 v-else class="text-left text-2xl">Let’s get started...</h3>
            <p v-if="progress == 0" class="text-sm font-proximaLight">
              Set up your account and learn how to get the most from PerPetual.
            </p>
            <p v-else class="text-sm font-proximaLight">
              Set up your pet records to start tracking health and behavioural
              data.
            </p>
          </div>
        </div>
        <FirstPetrecord :allChecked="detailsProgress.pet_created" />
        <ActivateMedicalHistory
          :firstPetRecord="detailsProgress.pet_created"
          :allChecked="
            detailsProgress.pet_identity_verified &&
            detailsProgress.vaccination_added &&
            detailsProgress.nutritional_plan_added
          "
          :checklist1="detailsProgress.pet_identity_verified"
          :checklist2="detailsProgress.vaccination_added"
          :checklist3="detailsProgress.nutritional_plan_added"
          :propsProgress="progress"
        />
        <AddCareRoutine
          :allChecked="detailsProgress.care_routine_created"
          :medicalHistoryChecked="
            detailsProgress.pet_identity_verified &&
            detailsProgress.vaccination_added &&
            detailsProgress.nutritional_plan_added
          "
          :propsProgress="progress"
        />
        <AddEmergencyContact
          :allChecked="detailsProgress.emergency_contact_created"
        />
        <SharePetRecords
          :careRoutineChecked="detailsProgress.care_routine_created"
          :allChecked="detailsProgress.pet_record_shared"
          :propsProgress="progress"
        />
        <CreateDiaryEntry
          :sharePetRecordsChecked="detailsProgress.pet_record_shared"
          :allChecked="detailsProgress.diary_entry_created"
          :propsProgress="progress"
        />
        <AddFavoriteService
          :allChecked="
            detailsProgress.connected_favourite ||
            detailsProgress.suggested_service
          "
          :checklist1="detailsProgress.connected_favourite"
          :checklist2="detailsProgress.suggested_service"
        />
        <div
          v-if="progress == 100"
          class="mt-16 text-left font-proximaMedium text-subheaderText"
        >
          <h3 class="text-xl">Finished onboarding!</h3>
          <p class="mt-1 text-sm font-proximaLight">
            Next time, you’ll see a view of your dashboard :-) <br /><br />

            PS. Notifications are bundled and delivered once daily by default.
            You can change the settings for individual features.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirstPetrecord from "./dropdownsection/FirstPetRecord.vue";
import ActivateMedicalHistory from "./dropdownsection/ActivateMedicalHistory.vue";
import AddCareRoutine from "./dropdownsection/AddCareRoutine.vue";
import AddEmergencyContact from "./dropdownsection/AddEmergencyContact.vue";
import SharePetRecords from "./dropdownsection/SharePetRecords.vue";
import CreateDiaryEntry from "./dropdownsection/CreateDiaryEntry.vue";
import AddFavoriteService from "./dropdownsection/AddFavoriteService.vue";
import PanicButtonIcon from "../icons/PanicButtonIcon.vue";
import DataserviceLogo from "../DataserviceLogo.vue";
import NavMenu from "../navigationmenu/NavMenu.vue";

import { getProgress } from "../../services/commonService";
import { mapGetters } from "vuex";

export default {
  components: {
    NavMenu,
    FirstPetrecord,
    ActivateMedicalHistory,
    AddCareRoutine,
    AddEmergencyContact,
    SharePetRecords,
    CreateDiaryEntry,
    AddFavoriteService,
    DataserviceLogo,
    PanicButtonIcon,
  },
  data() {
    return {
      progress: "",
      detailsProgress: "",
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      role: "auth/role",
    }),
  },
  async created() {
    const { data: progress } = await getProgress();
    console.log(progress);
    this.progress = progress.progress;
    this.detailsProgress = progress.details;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  user-select: none !important;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.menuButton1 {
  //width: 192px;
  padding-left: 20px;
  padding-right: 20px;
  height: 43px;
  background: #ffffff;
  border-radius: 40px;
  z-index: 100;
}

.menuButton2 {
  position: relative;
  margin-left: -30px;
  padding-left: 40px;
  padding-right: 30px;
  height: 43px;
  background: linear-gradient(270deg, #09a89d 1.41%, #0ac0cb 23.78%);
  border-radius: 40px;
  z-index: -1;
}

.tooltip {
  position: absolute;
  top: 5px;
  font-size: 14px;
  background: #f8961f;
  color: #fafafa;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  animation: fadein 1.7s;
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #f8961f;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.box {
  animation-duration: 1.2s;
  animation-iteration-count: inherit;
}

.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-50px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-7px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
