var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-12"},[_c('div',{staticClass:"flex justify-start items-end"},[_vm._m(0),[_c('div',{staticClass:"text-sm text-right font-proximaLight flex justify-center items-center"},[_c('NavMenu',{staticClass:"block",attrs:{"user":_vm.user,"role":_vm.role}})],1)]],2),_c('div',{staticClass:"mt-12 flex flex-col justify-center items-center"},[_c('div',{staticClass:"w-10/12 lg:w-7/12 flex flex-col justify-center items-center"},[_c('div',{staticClass:"w-full mb-14 text-left font-proximaMedium text-subheaderText"},[_c('div',{staticClass:"w-full flex flex-col justify-start"},[(_vm.progress == 0)?_c('h3',{staticClass:"text-left text-2xl"},[_vm._v(" Get Started with PerPetual ")]):_c('h3',{staticClass:"text-left text-2xl"},[_vm._v("Let’s get started...")]),(_vm.progress == 0)?_c('p',{staticClass:"text-sm font-proximaLight"},[_vm._v(" Set up your account and learn how to get the most from PerPetual. ")]):_c('p',{staticClass:"text-sm font-proximaLight"},[_vm._v(" Set up your pet records to start tracking health and behavioural data. ")])])]),_c('FirstPetrecord',{attrs:{"allChecked":_vm.detailsProgress.pet_created}}),_c('ActivateMedicalHistory',{attrs:{"firstPetRecord":_vm.detailsProgress.pet_created,"allChecked":_vm.detailsProgress.pet_identity_verified &&
          _vm.detailsProgress.vaccination_added &&
          _vm.detailsProgress.nutritional_plan_added,"checklist1":_vm.detailsProgress.pet_identity_verified,"checklist2":_vm.detailsProgress.vaccination_added,"checklist3":_vm.detailsProgress.nutritional_plan_added,"propsProgress":_vm.progress}}),_c('AddCareRoutine',{attrs:{"allChecked":_vm.detailsProgress.care_routine_created,"medicalHistoryChecked":_vm.detailsProgress.pet_identity_verified &&
          _vm.detailsProgress.vaccination_added &&
          _vm.detailsProgress.nutritional_plan_added,"propsProgress":_vm.progress}}),_c('AddEmergencyContact',{attrs:{"allChecked":_vm.detailsProgress.emergency_contact_created}}),_c('SharePetRecords',{attrs:{"careRoutineChecked":_vm.detailsProgress.care_routine_created,"allChecked":_vm.detailsProgress.pet_record_shared,"propsProgress":_vm.progress}}),_c('CreateDiaryEntry',{attrs:{"sharePetRecordsChecked":_vm.detailsProgress.pet_record_shared,"allChecked":_vm.detailsProgress.diary_entry_created,"propsProgress":_vm.progress}}),_c('AddFavoriteService',{attrs:{"allChecked":_vm.detailsProgress.connected_favourite ||
          _vm.detailsProgress.suggested_service,"checklist1":_vm.detailsProgress.connected_favourite,"checklist2":_vm.detailsProgress.suggested_service}}),(_vm.progress == 100)?_c('div',{staticClass:"mt-16 text-left font-proximaMedium text-subheaderText"},[_c('h3',{staticClass:"text-xl"},[_vm._v("Finished onboarding!")]),_vm._m(1)]):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-6/12"},[_c('img',{staticClass:"h-32 w-auto px-4 md:pl-16 lg:pl-32 pt-8 hidden md:flex",attrs:{"src":require("@/assets/images/dataservice-logo.svg"),"alt":"dataservice-logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-1 text-sm font-proximaLight"},[_vm._v(" Next time, you’ll see a view of your dashboard :-) "),_c('br'),_c('br'),_vm._v(" PS. Notifications are bundled and delivered once daily by default. You can change the settings for individual features. ")])
}]

export { render, staticRenderFns }