<template>
  <div class="my-6">
    <OnboardingSection />
  </div>
</template>
  
  <script>
import OnboardingSection from "../components/onboarding/OnboardingSection.vue";

export default {
  components: {
    OnboardingSection,
  },
};
</script>